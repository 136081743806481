<template>
  <div>
    <div class="content-tab border-top-line mb-5">
      <v-container
        fluid
        style="width: 100%"
      >
        <v-row>
          <v-col
            class="pa-0"
            cols="12"
            sm="12"
          >
            <div class="width-100 display-flex align-items-center justify-content-flex-end mb-3">
              <v-btn
                v-if="isResponsive"
                @click="dialogProfileAddDocument"
                class="button-primary mon-bold"
                elevation="0"
                icon
              >
                <v-icon size="18px">mdi-plus-thick</v-icon>
              </v-btn>
              <v-btn
                v-else
                @click="dialogProfileAddDocument"
                class="button-yellow mon-bold"
                elevation="0"
              >
                {{ texts.attachments.buttonAddDocument }}
              </v-btn>
            </div>
            <div class="width-100">
              <div v-if="documents.length > 0">
                <div v-if="isResponsive">
                  <div
                    v-for="(item, index) in documents"
                    :key="index"
                    class="card-content mb-5"
                  >
                    <div class="display-flex align-items-flex-start">
                      <p class="mon-bold">{{ texts.attachments.table.headers[0].text }}</p>
                      <v-spacer />
                      <p
                        class="mon-regular text-right"
                        style="width: 100%; overflow-wrap: break-wrap; text-align: right;"
                      >{{ item.sCustomerFileTypeName }}</p>
                    </div>
                    <div class="display-flex align-items-flex-start">
                      <p class="mon-bold">{{ texts.attachments.table.headers[1].text }}</p>
                      <v-spacer />
                      <p
                        class="mon-regular text-link text-right"
                        style="width: 100%; overflow-wrap: break-wrap; text-align: right;"
                        @click="openDocument(item.sFileUrl)"
                      >
                        {{ item.sCustomerFileName }}
                      </p>
                    </div>
                    <div>
                      <v-btn
                        @click="deleteDocument(item.sCustomerFileTypeId)"
                        class="button-tertiary mt-5 mb-2"
                        style="width: 100%;"
                        elevation="0"
                      >
                        {{ selectLanguage == 'sp' ? 'Eliminar' : 'Delete' }}
                      </v-btn>
                    </div>
                  </div>
                </div>
                <div
                  v-else
                  class="card-content"
                >
                  <v-simple-table>
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th
                            v-for="(item, index) in texts.attachments.table.headers"
                            :key="index"
                            :class="`${item.align} text-header-title mon-bold`"
                          >
                            {{ item.text }}
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="(item, index) in documents"
                          :key="index"
                        >
                          <td class="text-left mon-regular border-bottom">{{ item.sCustomerFileTypeName }}</td>
                          <td
                            class="text-center mon-regular text-link border-bottom"
                            @click="openDocument(item.sFileUrl)"
                          >
                            {{ item.sCustomerFileName }}
                          </td>
                          <td class="text-right mon-regular border-bottom">
                            <v-btn
                              @click="deleteDocument(item.sCustomerFileTypeId)"
                              icon
                            >
                              <v-icon
                                color="#D8686A"
                                size="18px"
                              >
                                mdi-delete
                              </v-icon>
                            </v-btn>
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </div>
              </div>
              <div
                v-else
                class="card-content-empty display-flex align-items-center justify-content-center"
              >
                <p
                  class="mon-regular"
                  style="color:#c1c1c3; font-size: 20px;"
                >{{ texts.profile.tab3.empty }}</p>
              </div>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </div>
</template>

<script>
export default {
  name: "ProfileTabContentThreeLayout",
  props: {
    texts: {
      type: Object,
      default: {},
    },
  },
  data() {
    return {
      //VARIABLES
      iCurrentPage: 1,
      lengthPage: 15,
      documents: [],
      isResponsive: false,
    };
  },
  beforeMount() {
    this.getFiles();
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.addEventListener("resize", this.handleResize);
  },
  methods: {
    handleResize: function () {
      if (window.innerWidth < 960) {
        this.isResponsive = true;
      } else {
        this.isResponsive = false;
      }
    },
    openDocument: function (url) {
      window.open(url, "_blank");
    },
    dialogProfileAddDocument: function () {
      this.$store.commit("setDialogProfileAddDocument", true);
    },
    deleteDocument: function (typeFileId) {
      this.$store.commit("setDialogDelete", {
        active: true,
        title: this.texts.attachments.textTitleDialogDelete,
        api: `${URI}/api/v1/${this.selectLanguage}/customers/enterprises/${this.$store.state.sEnterpriseId}/files/${typeFileId}`,
        redirect: "",
      });
    },
    getFiles: function () {
      const config = {
        headers: {
          Authorization: `Bearer ${this.$store.state.sToken}`,
        },
      };

      DB.get(
        `${URI}/api/v1/${this.selectLanguage}/customers/enterprises/${this.$store.state.sEnterpriseId}/files`,
        config
      )
        .then((response) => {
          this.documents = response.data.results;
          this.$store.commit("refresher", false);
        })
        .catch((error) => {
          this.mixError(error.response.data.message, error.response.status);
        });
    },
  },
  computed: {
    selectLanguage: function () {
      return this.$store.state.language;
    },
    refresh() {
      return this.$store.state.refresh;
    },
  },
  watch: {
    selectLanguage: function () {
      if (this.selectLanguage) {
        this.texts.profile[this.selectLanguage];
      }
    },
    refresh: function () {
      this.getFiles();
    },
  },
};
</script>

<style scoped>
.border-bottom {
  border-bottom: 1px solid #e0e0e0;
}

.button-yellow {
  background: transparent linear-gradient(254deg, #ffc556 0%, #fffc81 100%) 0%
    0% no-repeat padding-box;
  border-radius: 10px;
  opacity: 1;
  font-size: 14px;
  height: 40px !important;
  padding-left: 25px;
  padding-right: 25px;
  text-transform: initial;
}

.text-link {
  color: #0971fb;
  text-decoration: underline;
}

.text-link:hover {
  cursor: pointer;
}

.text-header-title {
  color: #000000 !important;
  font-size: 14px !important;
  border-bottom: 1px solid #949494 !important;
}

.card-content {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border: 1px solid #e9e9e9;
  border-radius: 4px;
  opacity: 1;
  padding: 10px;
}

.card-content-empty {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border: 1px solid #e9e9e9;
  border-radius: 4px;
  opacity: 1;
  padding: 10px;
  height: 250px;
}

.content-tab {
  padding: 40px 0px;
}

.border-top-line {
  border-top: 1px solid #cecece;
}

.border-bottom-line {
  border-bottom: 1px solid #cecece;
}
</style>